import React, { useContext } from 'react';
import { Link } from 'gatsby';
import './call-to-action.scss';
import { ArrowForward } from '@material-ui/icons';
import { LangContext } from '../Layout';
import Img from 'gatsby-image';

const CallToAction = ({ project }) => {
  const { language } = useContext(LangContext);
  return (
    <div className={'cta'}>
      <div className="cta__left">
        <span className="cta__left__title">{project['title_' + language]}</span>
        <span className="cta__left__description">
          {project['subtitle_' + language]}
        </span>
        <Link to="/contact/#discuss" className="cta__left__button">
          {project['button_' + language]}
          <ArrowForward fontSize="small" className="materiel-icon" />
        </Link>
      </div>
      {project.image.childImageSharp && (
        <Img
          className={'cta__image'}
          fluid={project.image.childImageSharp.fluid}
          alt={'projet'}
        />
      )}
    </div>
  );
};

export default CallToAction;
