import React, { useContext } from 'react';
import './style.scss';
import { LangContext } from '../Layout';

const Stat = ({ stat }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="stat">
      <div className="stat__number">{stat.number}</div>
      <div className="stat__text">{stat['desc_' + language]}</div>
    </div>
  );
};

const History = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="history">
      <div className="history__title">{data['title_' + language]} </div>
      <div className="history__stats">
        {data.stats.map((stat, index) => (
          <Stat stat={stat} key={index} />
        ))}
      </div>
    </div>
  );
};

export default History;
