import React, { useContext } from 'react';
import './style.scss';
import { LangContext } from '../Layout';

const Value = ({ card }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="value">
      <div className="value__title">{card['title_' + language]} </div>
      <div className="value__desc">{card['desc_' + language]}</div>
    </div>
  );
};

const Values = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="values">
      <div className="values__title">{data['title_' + language]}</div>
      <div className="values__cards">
        {data.cards.map((card, index) => (
          <Value card={card} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Values;
