import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Story from '../components/story/story';
import TheodoGroup from '../components/theodoGroup/TheodoGroup';
import History from '../components/history/History';
import WhatWeDo from '../components/what-we-do/WhatWeDo';
import Values from '../components/values/Values';
import CallToAction from '../components/call-to-action/CallToAction';

export const AboutPageTemplate = ({
  brief,
  theodoGroup,
  history,
  whatWeDo,
  values,
  project,
}) => {
  return (
    <section>
      {brief && <Story data={brief} />}
      {theodoGroup && <TheodoGroup data={theodoGroup} />}
      {history && <History data={history} />}
      {whatWeDo && <WhatWeDo data={whatWeDo} />}
      {values && <Values data={values} />}
      {project && <CallToAction project={project} />}
    </section>
  );
};

AboutPageTemplate.propTypes = {
  brief: PropTypes.object,
  team: PropTypes.object,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const {
    brief,
    theodoGroup,
    history,
    whatWeDo,
    values,
    project,
  } = post.frontmatter;

  return (
    <Layout>
      <AboutPageTemplate
        brief={brief}
        theodoGroup={theodoGroup}
        history={history}
        whatWeDo={whatWeDo}
        values={values}
        project={project}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        brief {
          title_fr
          title_en
          description_fr
          description_en
        }
        theodoGroup {
          logo {
            publicURL
          }
        }
        project {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          button_en
          button_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        history {
          title_en
          title_fr
          stats {
            number
            desc_fr
            desc_en
          }
        }
        values {
          title_en
          title_fr
          cards {
            title_en
            title_fr
            desc_fr
            desc_en
          }
        }
        whatWeDo {
          title_en
          title_fr
          headline_fr
          headline_en
          desc_fr
          desc_en
        }
      }
    }
  }
`;
