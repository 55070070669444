import React from 'react';
import './style.scss';

const TheodoGroup = ({ data }) => {
  return (
    <div className="theodo-group">
      <img src={data.logo.publicURL} width={492} alt="Theodo Group" />
    </div>
  );
};

export default TheodoGroup;
