import React, { useContext } from 'react';

import './style.scss';
import { LangContext } from '../Layout';

const Story = ({ data }) => {
  const { language } = useContext(LangContext);

  const descriptions = data['description_' + language].split('\n');

  return (
    <div className="story">
      <div className="story__title">{data['title_' + language]}</div>

      <div className="story__desc">
        {descriptions.map((description, index) => (
          <p key={index} className="story__desc__text">
            {description}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Story;
