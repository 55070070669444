import React, { useContext } from 'react';

import './style.scss';
import { LangContext } from '../Layout';
import ReactMarkdown from 'react-markdown';

const WhatWeDo = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="what-we-do">
      <div className="what-we-do__title">{data['title_' + language]}</div>
      <div className="what-we-do__headline">{data['headline_' + language]}</div>
      <div className="what-we-do__desc">
        <ReactMarkdown source={data['desc_' + language]} />
      </div>
    </div>
  );
};

export default WhatWeDo;
